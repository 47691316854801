import { useShop } from "hooks/firestore/simple/useShop"
import { useUser } from "hooks/firestore/simple/useUser"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
// eslint-disable-next-line css-modules/no-unused-class
import { useB2bCustomer } from "hooks/firestore/simple/useB2bCustomer"
import { usePrivateUser } from "hooks/firestore/simple/usePrivateUser"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"

export default function B2BLabel() {
  const user = useUser()
  const privateUser = usePrivateUser()

  const shop = useShop()

  const isB2BStorefront = useB2BStorefront()
  const isAdminStorefront = useAdminStorefront()
  const b2bCustomer = useB2bCustomer()

  // Hide on admin storefront
  if (isAdminStorefront) {
    return null
  }

  // Check if private user has all data filled out
  const hasAllB2BDataFilledOut = privateUser?.b2b?.businessName && privateUser?.b2b?.address

  if (!user || !shop || !hasAllB2BDataFilledOut || !b2bCustomer) {
    return null
  }

  if (isB2BStorefront) {
    return <span className="tw-bg-white tw-text-black tw-font-bold tw-px-2 tw-py-1 tw-rounded-md">B2B</span>
  }

  return <span className="tw-bg-white tw-text-black tw-font-bold tw-px-2 tw-py-1 tw-rounded-md">B2C</span>
}
